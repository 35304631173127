<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Ubah Anggaran</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="bidang">Bidang<strong>*</strong></label>
                  <v-select :options="optRole" v-model="form.bidang" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="periode">Tahun Anggaran</label>
                  <input type="text" class="form-control" id="periode" v-model="form.periode">
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="kode_rekening">Kode Rekening<strong>*</strong></label>
                  <input type="text" class="form-control" id="kode_rekening" v-model="form.kode_rekening">
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="kode_rekening">Nama Rekening<strong>*</strong></label>
                  <input type="text" class="form-control" id="nama_rekening" v-model="form.nama_rekening">
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="uraian">Uraian</label>
                  <textarea class="form-control" v-model="form.uraian" id="uraian"></textarea>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="pagu">Pagu<strong>*</strong></label>
                  <vue-number :value="Number(form.pagu)" @change="form.pagu = $event" :options="config" class="form-control text-right" @focus.native="$event.target.select()"/>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="nip">Pejabat PPTK<strong>*</strong></label>
                  <v-select :options="optPegawai" :reduce="id => id.code" v-model="form.pptk_id" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="Bendahara">Bendahara<strong>*</strong></label>
                  <v-select :options="optPegawai" :reduce="id => id.code" v-model="form.bendahara_id" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="pengguna">Pengguna Anggaran<strong>*</strong></label>
                  <v-select :options="optPegawai" :reduce="id => id.code" v-model="form.pengguna_id" class="style-chooser"></v-select>
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDataService, putDataService } from '../../../store/modules/crudservices'
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { formatCurrency } from '../../../_utils/form-config'

export default {
  created(){
    document.title = "Tambah Pagu"
  },
  name:'UserAdd',
  data() {
    return {
      config: formatCurrency,
      optRole: [],
      form:{},
    }
  },
  methods: {
    getAnggaran(id) {
      const url = '/anggaran/' + id;
      getDataService(url).then(response => {
        this.form = response.data
      })
    },
    getRole() {
      const url = '/role-search';
      getDataService(url).then(response => {
        this.optRole = response.data
      })
    },
    getPegawai() {
      const url = '/pegawai-search?filter=report';
      getDataService(url).then(response => {
        this.optPegawai = response.data
      })
    },
    formSave(){
      const form = this.form
      const _data = {
        '_method': 'PUT',
        'bidang': form.bidang,
        'kode_rekening': form.kode_rekening,
        'periode': form.periode,
        'nama_rekening': form.nama_rekening,
        'uraian': form.uraian,
        'pagu': form.pagu,
        'pptk_id': form.pptk_id,
        'bendahara_id': form.bendahara_id,
        'pengguna_id': form.pengguna_id
      }

      const url = '/anggaran/' + this.$route.params.id
      putDataService(url, _data).then(response => {
        if(response.data.success) this.$router.push('/master/anggaran')
      })
    },
    formCancel(){
      this.$router.push('/master/anggaran') 
    },
  },
  mounted() {
    this.getPegawai()
    this.getRole()
    setTimeout(() => {
      this.getAnggaran(this.$route.params.id)
    }, 500);
  },
  components: {
    VueNumber,
  },
}
</script>